.ag-root ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.ag-root ::-webkit-scrollbar-track-piece {
  background: #e0e0e0;
  border-radius: 10px;
}

.ag-root ::-webkit-scrollbar-thumb:hover {
  background: #aaa;
}

.ag-body-horizontal-scroll:not(.ag-scrollbar-invisible)
  .ag-horizontal-left-spacer:not(.ag-scroller-corner) {
  border-right: none;
}
.ag-body-horizontal-scroll:not(.ag-scrollbar-invisible)
  .ag-horizontal-right-spacer:not(.ag-scroller-corner) {
  border-left: none;
}

.ag-pinned-left-header {
  border-right: none;
}
.ag-pinned-right-header {
  border-left: none;
}
.ag-header {
  height: 40px !important;
  min-height: 40px !important;
}
.ag-header-cell {
  background-color: rgb(250, 251, 252);
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 0.875rem;
  padding: 6px 16px;
  height: 40px;
  overflow: visible;
}
.ag-sort-indicator-icon {
  scale: 0.7;
}
.ag-header-cell-comp-wrapp {
  height: 40px;
}
.ag-root-wrapper {
  border: none;
}
.ag-cell-label-container {
  height: 40px;
}
.ag-cell .ag-Grid-controlsColumn {
  background-color: rgba(255, 255, 255, 0.9);
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.875rem;
  font-weight: 400;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  border-bottom: 1px solid rgb(224, 224, 224);
}
.ag-overlay-loading-center {
  display: none;
}
.ag-row-position-absolute {
  background-color: rgba(255, 255, 255, 0.3);
}
.ag-row-hover {
  background-color: rgba(250, 251, 252, 0.5) !important;
}

.ag-cell-focus {
  border: none;
}

.ag-ltr .ag-cell-focus:not(.ag-cell-range-selected):focus-within,
.ag-ltr .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected),
.ag-ltr .ag-full-width-row.ag-row-focus:focus .ag-cell-wrapper.ag-row-group,
.ag-ltr .ag-cell-range-single-cell,
.ag-ltr .ag-cell-range-single-cell.ag-cell-range-handle,
.ag-rtl .ag-cell-focus:not(.ag-cell-range-selected):focus-within,
.ag-rtl .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected),
.ag-rtl .ag-full-width-row.ag-row-focus:focus .ag-cell-wrapper.ag-row-group,
.ag-rtl .ag-cell-range-single-cell,
.ag-rtl .ag-cell-range-single-cell.ag-cell-range-handle {
  border: none;
}
.ag-theme-quartz,
.ag-theme-quartz-dark,
.ag-theme-quartz-auto-dark {
  --ag-active-color: #2196f3;
  --ag-background-color: #fff;
  --ag-foreground-color: #181d1f;
  --ag-border-color: color-mix(
    in srgb,
    transparent,
    var(--ag-foreground-color) 15%
  );
  --ag-secondary-border-color: var(--ag-border-color);
  --ag-header-background-color: color-mix(
    in srgb,
    var(--ag-background-color),
    var(--ag-foreground-color) 2%
  );
  --ag-tooltip-background-color: var(--ag-header-background-color);
  --ag-control-panel-background-color: var(--ag-header-background-color);
  --ag-subheader-background-color: transparent;
  --ag-invalid-color: #e02525;
  --ag-checkbox-unchecked-color: color-mix(
    in srgb,
    var(--ag-background-color),
    var(--ag-foreground-color) 30%
  );
  --ag-advanced-filter-join-pill-color: #f08e8d;
  --ag-advanced-filter-column-pill-color: #a6e194;
  --ag-advanced-filter-option-pill-color: #f3c08b;
  --ag-advanced-filter-value-pill-color: #85c0e4;
  --ag-header-column-resize-handle-color: #757575;
  --ag-icon-font-color: color-mix(
    in srgb,
    transparent,
    var(--ag-foreground-color) 90%
  );
  --ag-panel-background-color: color-mix(
    in srgb,
    var(--ag-background-color),
    var(--ag-foreground-color) 3%
  );
  --ag-panel-border-color: color-mix(
    in srgb,
    transparent,
    var(--ag-foreground-color) 20%
  );
  --ag-menu-background-color: color-mix(
    in srgb,
    var(--ag-background-color),
    var(--ag-foreground-color) 3%
  );
  --ag-menu-border-color: color-mix(
    in srgb,
    transparent,
    var(--ag-foreground-color) 20%
  );
  --ag-selected-row-background-color: transparent;
  --ag-row-hover-color: #f5f5f5;
  /*color-mix(in srgb, transparent, var(--ag-active-color) 12%);*/
  --ag-column-hover-color: color-mix(
    in srgb,
    transparent,
    var(--ag-foreground-color) 5%
  );
  --ag-input-focus-border-color: var(--ag-active-color);
  --ag-input-focus-box-shadow: 0 0 0 3px
    color-mix(in srgb, transparent, var(--ag-input-focus-border-color) 47%);
  --ag-range-selection-background-color: color-mix(
    in srgb,
    transparent,
    var(--ag-active-color) 20%
  );
  --ag-range-selection-background-color-2: color-mix(
    in srgb,
    transparent,
    var(--ag-active-color) 36%
  );
  --ag-range-selection-background-color-3: color-mix(
    in srgb,
    transparent,
    var(--ag-active-color) 49%
  );
  --ag-range-selection-background-color-4: color-mix(
    in srgb,
    transparent,
    var(--ag-active-color) 59%
  );
  --ag-checkbox-background-color: var(--ag-background-color);
  --ag-checkbox-checked-color: var(--ag-active-color);
  --ag-range-selection-border-color: var(--ag-active-color);
  --ag-secondary-foreground-color: var(--ag-foreground-color);
  --ag-input-border-color: var(--ag-border-color);
  --ag-input-border-color-invalid: var(--ag-invalid-color);
  --ag-disabled-foreground-color: color-mix(
    in srgb,
    transparent,
    var(--ag-foreground-color) 50%
  );
  --ag-chip-background-color: color-mix(
    in srgb,
    transparent,
    var(--ag-foreground-color) 7%
  );
  --ag-chip-border-color: color-mix(
    in srgb,
    var(--ag-header-background-color),
    var(--ag-foreground-color) 13%
  );
  --ag-input-disabled-border-color: var(--ag-border-color);
  --ag-input-disabled-background-color: color-mix(
    in srgb,
    var(--ag-background-color),
    var(--ag-foreground-color) 6%
  );
  --ag-modal-overlay-background-color: color-mix(
    in srgb,
    transparent,
    var(--ag-background-color) 66%
  );
  --ag-chart-menu-label-color: color-mix(
    in srgb,
    transparent,
    var(--ag-foreground-color) 80%
  );
  --ag-chart-menu-pill-select-button-color: color-mix(
    in srgb,
    transparent,
    var(--ag-foreground-color) 70%
  );
  --ag-borders: solid 1px;
  --ag-border-radius: 4px;
  --ag-wrapper-border-radius: 8px;
  --ag-borders-side-button: none;
  --ag-side-button-selected-background-color: transparent;
  --ag-header-column-resize-handle-display: block;
  --ag-header-column-resize-handle-width: 2px;
  --ag-header-column-resize-handle-height: 30%;
  --ag-grid-size: 8px;
  --ag-icon-size: 16px;
  --ag-header-height: calc(var(--ag-font-size) + var(--ag-grid-size) * 4.25);
  --ag-row-height: calc(var(--ag-font-size) + var(--ag-grid-size) * 3.5);
  --ag-list-item-height: calc(
    var(--ag-icon-size) + var(--ag-widget-vertical-spacing)
  );
  --ag-column-select-indent-size: var(--ag-icon-size);
  --ag-set-filter-indent-size: var(--ag-icon-size);
  --ag-filter-tool-panel-group-indent: var(--ag-grid-size);
  --ag-advanced-filter-builder-indent-size: calc(
    var(--ag-icon-size) + var(--ag-grid-size) * 2
  );
  --ag-cell-horizontal-padding: calc(var(--ag-grid-size) * 2);
  --ag-cell-widget-spacing: calc(var(--ag-grid-size) * 1.5);
  --ag-widget-container-vertical-padding: calc(var(--ag-grid-size) * 1.5);
  --ag-widget-container-horizontal-padding: calc(var(--ag-grid-size) * 1.5);
  --ag-widget-horizontal-spacing: calc(var(--ag-grid-size) * 1.5);
  --ag-widget-vertical-spacing: calc(var(--ag-grid-size) * 1);
  --ag-toggle-button-height: 18px;
  --ag-toggle-button-width: 28px;
  --ag-toggle-button-border-width: 2px;
  --ag-font-family: 'IBM Plex Sans', -apple-system, BlinkMacSystemFont,
    'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue',
    sans-serif;
  --ag-font-size: 14px;
  --ag-icon-font-family: agGridQuartz;
  --ag-tab-min-width: 290px;
  --ag-chart-menu-panel-width: 260px;
  --ag-card-shadow: 0 1px 4px 1px rgba(186, 191, 199, 0.4);
  --ag-popup-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.15);
  --ag-side-bar-panel-width: 250px;
}

.ag-header-cell-resize {
  width: 10px;
  cursor: col-resize;
}
.ag-header-cell .ag-header-cell-resize::before {
  content: '';
  position: absolute;
  z-index: 1;
  opacity: 1;
  display: var(--ag-header-column-resize-handle-display);
  width: var(--ag-header-column-resize-handle-width);
  height: var(--ag-header-column-resize-handle-height);
  top: calc(50% - var(--ag-header-column-resize-handle-height) * 0.5);
  background-color: var(--ag-header-column-resize-handle-color);
  right: -3px;
}

.ag-header-cell-resize + .ag-header-cell-resize::after {
  content: '';
  position: absolute;
  z-index: 1;
  opacity: 1;
  display: var(--ag-header-column-resize-handle-display);
  width: var(--ag-header-column-resize-handle-width);
  height: var(--ag-header-column-resize-handle-height);
  top: calc(50% - var(--ag-header-column-resize-handle-height) * 0.5);
  background-color: var(--ag-header-column-resize-handle-color);
  left: -3px;
}
.ag-pinned-right-header .ag-header-cell .ag-header-cell-resize::before {
  left: -3px;
}
.ag-pinned-right-header .ag-header-cell-resize + .ag-header-cell-resize::after {
  right: -3px;
}
.ag-header-cell-resize {
  display: none;
}
.ag-header-cell:hover .ag-header-cell-resize {
  display: block;
}

.ag-header-resizing .ag-header-cell-resize {
  display: block;
}
.ag-header-resizing-pinned-right .ag-header-cell-resize {
  display: block;
}

.ag-header-resizing-pinned-right {
  border-left: 2px solid #949494;
}
.ag-header-resizing {
  border-right: 2px solid #949494;
}
